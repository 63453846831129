import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import IcomoonReact from 'icomoon-react';
import { useNavigate } from 'react-router-dom';

import './style.scss';

import { ReactComponent as AppLogo } from '../assets/appLogo.svg';
import iconSet from '../assets/icomoon/selection.json';

const DRAWER_WIDTH = 268;
const OPACITY_HEX = 33;

export function Navigation() {
  const navigate = useNavigate();

  const tabs = [
    { title: 'Invoices', link: 'invoices', icon: 'paid', color: '#FFC93C' },
  ];
  const name = 'Albina Gevorgyan';

  const redirect = (link: string) => {
    navigate(link);
  };

  return (
    <Drawer
      variant='permanent'
      anchor='left'
      sx={{
        width: DRAWER_WIDTH,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          boxShadow: 2,
        },
      }}
    >
      <Box marginLeft='24px' marginTop='40px'>
        <AppLogo />
        <Typography fontSize={20} marginTop='12px'>
          {name}
        </Typography>
      </Box>
      <Toolbar />
      <Divider className='divider' />
      <List>
        {tabs.map((tab) => (
          <ListItem key={tab.title} disablePadding>
            <ListItemButton
              disableRipple
              className='navButton'
              onClick={() => redirect(tab.link)}
            >
              <ListItemIcon
                className='navButtonIcon'
                sx={{ background: tab.color + OPACITY_HEX }}
              >
                <IcomoonReact
                  icon={tab.icon}
                  iconSet={iconSet}
                  size={20}
                  color={tab.color}
                />
              </ListItemIcon>
              <ListItemText primary={tab.title} className='navButtonText' />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
