import { Box, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { Navigation } from '../../navigation';

export function Layout() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Navigation />
      <Box className='outlet' padding='40px' flexGrow={1}>
        <Outlet />
      </Box>
      <ToastContainer />
    </Box>
  );
}
